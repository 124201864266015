<template>
	<tabela-karte :danas="true"></tabela-karte>
</template>

<script>
export default {
	name: 'karte-danas-list',
	mounted() {
		this.$store.dispatch('setHeader', {
			title: this.$t('prodateKarte.karteDanas')
		});
	},
	components: {
		tabelaKarte: require('./tabela').default
	}
};
</script>
