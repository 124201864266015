<template>
	<div class="page">
		<el-row class="tool-bar">
			<el-col :span="12">
				<search-tool v-model="search" @input="$utils('search', getKarte)"/>
			</el-col>
		</el-row>
		<el-row>
			<el-table v-if="karte.length"
						:data="karte"
						ref="table"
						:default-sort="sortBy"
						@sort-change="sort => $utils('changeSort', sort, getKarte)"
						v-loading="gridLoad"
						:row-class-name="tableRowClassName"
						border>
				<el-table-column
						align="left"
						prop="datum"
						:label="$t('prodateKarte.datumKupovine')"
						sortable="custom">
					<template slot-scope="scope">
						{{$utils('formatDate', scope.row.datum, 'DD.MM.YYYY HH:mm')}}
					</template>
				</el-table-column>
				<el-table-column
						align="left"
						prop="broj_karte"
						:label="$t('prodateKarte.brojKarte')"
						width="150"
						sortable="custom">
				</el-table-column>
				<el-table-column
						align="left"
						prop="kupon"
						:label="$t('prodateKarte.kupon')"
						width="130"
						sortable="custom">
				</el-table-column>
				<el-table-column
						align="left"
						prop="status_karte"
						:label="$t('prodateKarte.status')"
						sortable="custom">
					<template slot-scope="scope">
						{{scope.row.six_tekst}}
					</template>
				</el-table-column>
				<el-table-column
						align="center"
						width="350"
						:label="$t('prodateKarte.status')">
					<template slot-scope="scope">
						<el-row :gutter="20" class="bg-purple-dark">
							<el-col :span="11" class="text-right warning">
								{{scope.row.mesto_start_naziv}} ({{scope.row.drzava_start_skraceno}})
							</el-col>
							<el-col :span="2">
								-
							</el-col>
							<el-col :span="11" class="text-left">
								{{scope.row.mesto_end_naziv }} ({{scope.row.drzava_end_skraceno}})
							</el-col>
						</el-row>
						<el-row :gutter="20">
							<el-col :span="11" class="text-right">
								{{$t('prodateKarte.odlazak')}}
							</el-col>
							<el-col :span="2">
								/
							</el-col>
							<el-col :span="11" class="text-left">
								{{$t('prodateKarte.povratak')}}
							</el-col>
						</el-row>
						<el-row :gutter="20">
							<el-col :span="11" class="text-right">
								{{$utils('formatDate', scope.row.datum_start)}}
							</el-col>
							<el-col :span="2">
								/
							</el-col>
							<el-col :span="11" class="text-left">
								{{$utils('formatDate', scope.row.datum_end)}}
							</el-col>
						</el-row>
						<el-row :gutter="20" class="bg-purple-dark">
							<el-col :span="12" class="text-right">
								{{$t('prodateKarte.ukupnoPutnika')}}
							</el-col>
							<el-col :span="12" class="text-left">
								{{scope.row.ukupno_putnika}}
							</el-col>
						</el-row>
						<el-row :gutter="20">
							<el-col :span="12" class="text-right">
								{{$t('prodateKarte.ukupnaCena')}}
							</el-col>
							<el-col :span="12" class="text-left">
								{{$utils('formatBroja', scope.row.ukupna_cena)}}
							</el-col>
						</el-row>
					</template>
				</el-table-column>
				<el-table-column
						align="center"
						width="350"
						:label="$t('prodateKarte.podaciPlatioca')">
					<template slot-scope="scope">
						<el-row :gutter="20" class="bg-purple-dark">
							<el-col :span="24">
								<span v-if="scope.row.kartica_info && scope.row.kartica_info.length !== 0">{{scope.row.kartica_info.vlasnik}}</span>
								<span v-else>{{scope.row.platilac ? scope.row.platilac.ime : '-'}} {{scope.row.platilac ? scope.row.platilac.prezime : '-'}}</span>
							</el-col>
						</el-row>
						<el-row :gutter="20">
							<el-col :span="12" class="text-right">
								{{$t('prodateKarte.telefoni')}}
							</el-col>
							<el-col :span="12" class="text-left">
								{{scope.row.platilac ? scope.row.platilac.broj_telefona_1 : (scope.row.platilac ? scope.row.platilac.broj_telefona_2 : (scope.row.platilac ? scope.row.platilac.broj_telefona_3 : '-'))}}
							</el-col>
						</el-row>
						<el-row :gutter="20">
						</el-row>
						<el-row :gutter="20">
							<el-col :span="24">
								<el-input size="mini" v-model="scope.row.platilac.email">
									<el-button slot="append" icon="el-icon-refresh-left" @click="kartePromeniMailPlatioca(scope.row.id, scope.row.platilac.email)"></el-button>
								</el-input>
							</el-col>
						</el-row>
						<el-row :gutter="20">
							<el-col :span="24">
								<span v-if="scope.row.kartica_info && scope.row.kartica_info.length !== 0">{{scope.row.kartica_info.naziv}}</span>
								<span v-else>{{ scope.row.platilac.vrsta_placanja }}</span>
							</el-col>
						</el-row>
						<el-row :gutter="20" v-if="scope.row.napomena">
							<el-col :span="24">
								<el-button type="info" icon="fas fa-comment" size="mini" @click="prikazNapomene(scope.row.napomena)" round></el-button>
							</el-col>
						</el-row>
					</template>
				</el-table-column>
				<el-table-column
						width="100"
						align="center">
					<template slot-scope="scope">
						<el-row v-if="scope.row.id_kod_greske_six === 110 || scope.row.id_kod_greske_six === 120 || scope.row.id_kod_greske_six === 1">
							<el-col>
								<el-tooltip class="item" effect="dark" :content="$t('global.prikaz')" placement="left">
									<el-button size="mini" type="success" icon="fas fa-eye" @click="prikaziPDF(scope.row.token)"></el-button>
								</el-tooltip>
							</el-col>
						</el-row>
						<el-row v-if="scope.row.id_kod_greske_six === 1">
							<el-col>
								<el-tooltip class="item" effect="dark" :content="$t('prodateKarte.mailVozaca')" placement="left">
									<el-button size="mini" type="danger" icon="fas fa-envelope" @click="dialogTableVisible = true;dialogKartaId = scope.row.token;"></el-button>
								</el-tooltip>
							</el-col>
							<el-col>
								<el-tooltip class="item" effect="dark" :content="$t('prodateKarte.mailPutnika')" placement="left">
									<el-button size="mini" type="warning" icon="fas fa-envelope" @click="putnikMail(scope.row.token, scope.row.platilac.email)"></el-button>
								</el-tooltip>
							</el-col>
						</el-row>
						<el-row v-if="scope.row.id_kod_greske_six === 1">
							<el-col>
								<el-tooltip class="item" effect="dark" :content="$t('prodateKarte.refundirajUplatu')" placement="left">
									<el-button size="mini" type="primary" icon="el-icon-refresh-left" @click="karteRefundiraj(scope.row.id)"></el-button>
								</el-tooltip>
							</el-col>
						</el-row>
						<el-row v-if="scope.row.id_kod_greske_six === 120 || scope.row.id_kod_greske_six === 1">
							<el-col>
								<el-tooltip class="item" effect="dark" :content="$t('prodateKarte.stornirajUplatu')" placement="left">
									<el-button size="mini" type="danger" icon="el-icon-close" @click="karteStorniraj(scope.row.id)"></el-button>
								</el-tooltip>
							</el-col>
						</el-row>
						<el-row v-if="scope.row.id_kod_greske_six === 777">
							<el-col>
								<el-tooltip class="item" effect="dark" :content="$t('prodateKarte.validacijaUplatu')" placement="left">
									<el-button size="mini" type="success" icon="el-icon-check" @click="karteValidacijaUplate(scope.row.id)"></el-button>
								</el-tooltip>
							</el-col>
						</el-row>
					</template>
				</el-table-column>
			</el-table>
			<el-alert v-else :title="$t('global.nemaPodataka')" type="warning" :closable="false"/>
		</el-row>
		<br>
		<el-row>
			<pagination v-model="pagination" @input="getKarte" ref="pagination"/>
		</el-row>
		<el-dialog :title="$t('prodateKarte.mailVozaca')" :visible.sync="dialogTableVisible">
			<el-table :data="mailVozaci">
				<el-table-column
						property="mail">
				</el-table-column>
				<el-table-column>
					<template slot-scope="scope">
						<el-button size="small" type="primary" @click="vozacMail(dialogKartaId, scope.row.mail)" icon="el-icon-message"></el-button>
					</template>
				</el-table-column>
			</el-table>
		</el-dialog>
	</div>
</template>
<script>
export default {
	name: 'tabela-karte',
	props: {
		danas: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			dialogTableVisible: false,
			dialogKartaId: null,
			karte: [],
			mailVozaci: [],
			gridLoad: true,
			pagination: {},
			search: '',
			sortBy: {
				prop: 'datum',
				order: 'descending'
			}
		};
	},
	mounted() {
		this.$store.dispatch('setHeader', {
			title: this.$t('prodateKarte.karte')
		});

		if (this.$route.query.brojKarte)
			this.search = this.$route.query.brojKarte;

		this.$utils('autoLoad');
		this.$utils('stopLoadingAfter', [this.getKarte(), this.karteVozaciMail()]);
	},
	methods: {
		getKarte() {
			this.gridLoad = true;
			let params = this.$utils('getParams');
			if (this.danas === true)
				Object.assign(params, { danas: this.danas });

			return this.$get('karte', params).then(data => {
				this.karte = data.content;
				this.$utils('setPag', data);
				this.$utils('autoSave');
				this.gridLoad = false;
			});
		},
		prikaziPDF(token) {
			window.open(this.$openPdfUrl + token, '_blank');
		},
		karteValidacijaUplate(id) { // put
			this.$confirm(this.$t('confirm.opisValidacijaUplate'), this.$t('confirm.upozorenje'), {
				confirmButtonText: this.$t('global.uredu'),
				cancelButtonText: this.$t('global.odustani'),
				type: 'warning'
			}).then(() => {
				this.gridLoad = true;
				this.$api.karteValidacijaUplate.update({ id: id }, {}).then(() => {
					this.getKarte();
				});
			}).catch(() => {});
		},
		karteStorniraj(id) { // put
			this.$confirm(this.$t('confirm.opisStorniraj'), this.$t('confirm.upozorenje'), {
				confirmButtonText: this.$t('global.uredu'),
				cancelButtonText: this.$t('global.odustani'),
				type: 'warning'
			}).then(() => {
				this.gridLoad = true;
				this.$api.karteStorniraj.update({ id: id }, {}).then(() => {
					this.getKarte();
				});
			}).catch(() => {});
		},
		karteRefundiraj(id) { // put
			this.$confirm(this.$t('confirm.opisRefundiraj'), this.$t('confirm.upozorenje'), {
				confirmButtonText: this.$t('global.uredu'),
				cancelButtonText: this.$t('global.odustani'),
				type: 'warning'
			}).then(() => {
				this.gridLoad = true;
				this.$api.karteRefundiraj.update({ id: id }, {}).then(() => {
					this.getKarte();
				});
			}).catch(() => {});
		},
		kartePromeniMailPlatioca(id, email) { // put
			this.$confirm(this.$t('confirm.opisPromeniEmail'), this.$t('confirm.upozorenje'), {
				confirmButtonText: this.$t('global.uredu'),
				cancelButtonText: this.$t('global.odustani'),
				type: 'warning'
			}).then(() => {
				this.gridLoad = true;
				this.$api.kartePromeniMailPlatioca.update({}, JSON.stringify({ id_karte: id, novi_mail: email })).then(() => {
					this.putnikMail(id, email);
					this.getKarte();
				});
			}).catch(() => {});
		},
		karteVozaciMail() { // get
			this.$get('karteVozaciMail').then(data => {
				this.mailVozaci = data;
			});
		},
		posaljiEmail(token, email) {
			this.$get('pdfEmail', { token: token, email: email }).then(() => {
				this.$message(this.$t('confirm.emailPoslat'));
			});
		},
		vozacMail(token, email) {
			this.posaljiEmail(token, email);
			this.dialogKartaId = null;
		},
		putnikMail(token, email) {
			this.$confirm(this.$t('prodateKarte.msgSlanjeKartePutniku'), this.$t('confirm.upozorenje'), {
				confirmButtonText: this.$t('global.uredu'),
				cancelButtonText: this.$t('global.odustani'),
				type: 'warning'
			}).then(() => {
				this.posaljiEmail(token, email);
			});
		},
		tableRowClassName({ row, rowIndex }) {
			let className = '';

			if (row.id_kod_greske_six === 1)
				className = 'row1';
			else if (row.id_kod_greske_six === 0)
				className = 'row0';
			else if (row.id_kod_greske_six === -1 || row.id_kod_greske_six === -128)
				className = 'row-1-128';
			else if (row.id_kod_greske_six < -78)
				className = 'row-78';
			else if (row.id_kod_greske_six === 110)
				className = 'row110';
			else if (row.id_kod_greske_six === 120)
				className = 'row120';
			else if (row.id_kod_greske_six === 777)
				className = 'row777';

			return className;
		},
		prikazNapomene(napomena) {
			this.$alert(napomena, this.$t('prodateKarte.napomena'))
				.then(() => {})
				.catch(() => {});
		}
	}
};
</script>
<style lang="scss">
	.el-table .row1 {
		background-color: #67BE8A; // Placene
	}
	.el-table .row0 {
		background-color: #8C8F98; // Cekanje
	}
	.el-table .row-1-128 {
		background-color: #FF7575; // Neuspelo
	}
	.el-table .row-78 {
		background-color: #D98BB5; // Greske
	}
	.el-table .row110 {
		background-color: #FFD77E; // Storno
	}
	.el-table .row120 {
		background-color: #1AB4EF; // Refund
	}
	.el-table .row777 {
		background-color: rgba(0, 255, 46, 0.44); // six rezervacija
	}
	.el-table .bg-purple-dark {
		background: rgba(0, 0, 0, 0.15);
	}
	.el-table {
		color: #000;
	}
	.el-table_1_column_7 .el-row {
		.el-button {
			margin-bottom: 2px;
		}
	}
</style>
